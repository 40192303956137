import axios from 'axios';
import { BaseService } from '@/core/base-service';
import { IDocument } from '@/models/document.model';

export default class DocumentService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/document');
	}
	public retrieveByEmployeeId(employeeId: string): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}`);
	}

	public deleteById(entityId: string): Promise<any> {
		return axios.delete(`${this.endpointName}/${entityId}`);
	}

	public retrieveById(entityId: string): Promise<any> {
		return axios.get(`${this.endpointName}/${entityId}`);
	}

	public updateById(entity: IDocument): Promise<any> {
		return axios.put(`${this.endpointName}/${entity.id}`, entity);
	}

	public downloadById(entityId: string): Promise<any> {
		return axios.get(`${this.endpointName}/download?id=${entityId}`, {
			responseType: 'arraybuffer',
			headers: {
				Accept: 'application/octet-stream',
			},
		});
	}

	public downloadByAbsenceId(absenceId: string): Promise<any> {
		return axios.get(`${this.endpointName}/absence/download?absenceId=${absenceId}`, {
			responseType: 'arraybuffer',
			headers: {
				Accept: 'application/octet-stream',
			},
		});
	}
}
