import { BaseService } from '@/core/base-service';
import axios from 'axios';
import { getDateTimeFromDateTime } from '@rednex/gateway_core';

export default class ZefTimeRecordingService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/time-recording');
	}

	public checkTimeRecording(): Promise<any> {
		return axios.get(this.endpointName + `/get-by-current-user`);
	}

	public setTimeRecordingStartEnd(isTimeRecording: boolean): Promise<any> {
		return axios.post(this.endpointName + '/set-time-recording-start-end', {
			enabled: isTimeRecording,
			startTime: getDateTimeFromDateTime(new Date()),
		});
	}

	public checkRunningTimeRecording(): Promise<any> {
		return axios.post(this.endpointName + '/check-running-time-recording');
	}
}
