import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class JournalAdminService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/admin/journal');
	}

	public retrieveOverview(employeeId: string, targetMonth: any): Promise<any> {
		return axios.get(`${this.endpointName}/overview?employeeId=${employeeId}&month=${targetMonth}`);
	}
}
