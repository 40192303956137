import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/project');
	}

	public getOptions(date): Promise<any> {
		return axios.get(this.endpointName + '/combobox?date=' + date);
	}

	public getOptionsByDateAndCustomerId(date: string, customerId: string): Promise<any> {
		return axios.get(`${this.endpointName}/combobox?date=${date}&customerId=${customerId}`);
	}
}
