// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import App from './app.vue';
import VSwatches from 'vue3-swatches';
import Vue3TouchEvents from 'vue3-touch-events';
import mitt from 'mitt';
import { FilterService } from 'primevue/api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import { initPrimeVue } from '@/config/config-primevue';
import { initRouter } from '@/router';
import { projectStore } from '@/project/store/project-store';
import { reactive } from 'vue';
import { watch } from 'vue';

import AbsenceAdminService from '@/project/services/absence-admin.service';
import AbsenceService from '@/project/services/absence.service';
import AbsenceTypeService from '@/project/services/absence-type.service';
import AccessAuthorizationLineService from '@/project/services/access-authorization-line.service';
import AccessAuthorizationService from '@/project/services/access-authorization.service';
import AccountService from '@/account/account.service';
import BillingService from '@/project/services/billing.service';
import CalendarService from '@/project/services/calendar.service';
import ChangePasswordService from '@/account/change-password/change-password.service';
import CompanyService from '@/project/services/company.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import CountryService from '@/project/services/country.service';
import DashboardService from '@/project/services/dashboard.service';
import DocumentService from '@/project/services/document.service';
import DocumentTypeService from '@/project/services/document-type.service';
import EmployeeService from '@/project/services/employee.service';
import EmployeeServiceAgreementService from '@/project/services/employee-service-agreement.service';
import EmployeeSuperiorService from '@/project/services/employee-superior.service';
import FavoriteService from '@/project/services/favoriteService';
import GatewayService from '@/admin/gateway/gateway.service';
import HealthService from '@/admin/health/health.service';
import JournalAdminService from '@/project/services/journal-admin.service';
import JournalService from '@/project/services/journal.service';
import LoginService from '@/account/login.service';
import LogsService from '@/admin/logs/logs.service';
import MetricsService from '@/admin/metrics/metrics.service';
import OrderLineService from '@/project/services/order-line.service';
import OrderService from '@/project/services/order.service';
import ProjectAdminService from '@/project/services/project-admin.service';
import ProjectCategoryService from '@/project/services/project-category.service';
import ProjectCustomerService from '@/project/services/project-customer.service';
import ProjectLineAdminService from '@/project/services/project-line-admin.service';
import ProjectLineService from '@/project/services/project-line.service';
import ProjectLineTemplateService from '@/project/services/project-line-template.service';
import ProjectService from '@/project/services/project.service';
import ProjectTemplateService from '@/project/services/project-template.service';
import ProviderService from '@/admin/user-management/provider.service';
import SchemaService from '@/admin/tenant-management/schema-service';
import ServiceAgreementService from '@/project/services/service-agreement.service';
import TaskService from '@/project/services/task.service';
import TenantMasterService from '@/admin/tenant-management/tenant-master.service';
import TimeLineAdminService from '@/project/services/time-line-admin.service';
import TimeLineService from '@/project/services/time-line.service';
import PzeTimeRecordingService from '@/project/services/pze-time-recording.service';
import ToastService from 'primevue/toastservice';
import TranslationService from '@/locale/translation.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import WorkingHoursService from '@/project/services/working-hours.service';
import YouTrackService from '@/project/services/youtrack.service';
import YouTrackUserSettingService from '@/project/services/youtrack-user-setting.service';
import ZefTimeRecordingService from '@/project/services/zef-time-recording.service';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import { AlertService } from '@rednex/gateway_core';
import { AlertServiceConfiguration } from '@rednex/gateway_core';
import { getGlobalProps } from '@rednex/gateway_core';
import { initFortAwesome } from '@rednex/gateway_core';
import { initI18N } from '@rednex/gateway_core';
import { initVueXStore } from '@rednex/gateway_core';
import { JhiSortIndicator } from '@rednex/gateway_core';

import '../content/scss/global.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@rednex/gateway_core/componentstyles';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'tippy.js/dist/tippy.css';
import 'vue3-swatches/dist/style.css';

const emitter = mitt();
const globalProps = getGlobalProps();
const i18n = initI18N();
const router = initRouter();
const store = initVueXStore({ projectStore });

const translationService = new TranslationService();
const accountService = new AccountService(store, router);
const alertService = new AlertService(new AlertServiceConfiguration(false, 0, 5000, 5000, 10000));

router.beforeEach(async (to, from, next) => {
	const currentLanguage = store.getters.currentLanguage;
	await translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage, emitter);

	if (!to.matched.length) {
		next('/not-found');
	} else if (to.meta && to.meta.authorities && (to.meta.authorities as []).length > 0) {
		await accountService
			.reloadAuthentication(to.meta.authorities)
			.then(authenticated => {
				if (!authenticated) {
					sessionStorage.setItem('requested-url', to.fullPath);

					if (to.fullPath !== '' && to.fullPath !== '/') {
						next('/forbidden');
					} else {
						next();
					}
				} else {
					next();
				}
			})
			.catch(error => {
				alertService.showHttpError(globalProps, error);
			});
	} else {
		// no authorities, so just proceed
		next();
	}
});

/* tslint:disable */
export const app = createApp({
	el: '#app',
	components: { App } as any,
	template: '<App/>',
	provide: {
		absenceAdminService: new AbsenceAdminService(),
		absenceService: new AbsenceService(),
		absenceTypeService: new AbsenceTypeService(),
		accountService: accountService,
		accessAuthorizationLineService: new AccessAuthorizationLineService(),
		accessAuthorizationService: new AccessAuthorizationService(),
		alertService: alertService,
		billingService: new BillingService(),
		calendarService: new CalendarService(),
		changePasswordService: new ChangePasswordService(),
		companyService: new CompanyService(),
		configurationService: new ConfigurationService(),
		countryService: new CountryService(),
		dashboardService: new DashboardService(),
		documentService: new DocumentService(),
		documentTypeService: new DocumentTypeService(),
		employeeService: new EmployeeService(),
		employeeServiceAgreementService: new EmployeeServiceAgreementService(),
		employeeSuperiorService: new EmployeeSuperiorService(),
		favoriteService: new FavoriteService(),
		gatewayService: new GatewayService(),
		healthService: new HealthService(),
		journalService: new JournalService(),
		journalAdminService: new JournalAdminService(),
		loginService: new LoginService(),
		logsService: new LogsService(),
		metricsService: new MetricsService(),
		orderLineService: new OrderLineService(),
		orderService: new OrderService(),
		projectAdminService: new ProjectAdminService(),
		projectCategoryService: new ProjectCategoryService(),
		projectCustomerService: new ProjectCustomerService(),
		projectLineAdminService: new ProjectLineAdminService(),
		projectLineService: new ProjectLineService(),
		projectLineTemplateService: new ProjectLineTemplateService(),
		projectService: new ProjectService(),
		projectTemplateService: new ProjectTemplateService(),
		providerService: new ProviderService(),
		pzeTimeRecordingService: new PzeTimeRecordingService(),
		schemaService: new SchemaService(),
		serviceAgreementService: new ServiceAgreementService(),
		taskService: new TaskService(),
		tenantMasterService: new TenantMasterService(),
		timeLineAdminService: new TimeLineAdminService(),
		timeLineService: new TimeLineService(),
		translationService: translationService,
		userManagementService: new UserManagementService(),
		workingHoursService: new WorkingHoursService(),
		youTrackService: new YouTrackService(),
		youTrackUserSettingService: new YouTrackUserSettingService(),
		zefTimeRecordingService: new ZefTimeRecordingService(),
	},
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.router = router;
app.config.globalProperties.$appState = reactive({ themeMode: 'light' });

app.use(ToastService);
app.use(VSwatches);
app.use(Vue3TouchEvents);
app.use(i18n);
app.use(router);
app.use(store);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('jhi-sort-indicator', JhiSortIndicator);

initFortAwesome();
initPrimeVue(app);

FilterService.register('DATEEQUALS', (value, filter): boolean => {
	if (filter === undefined || filter === null) {
		return true;
	}
	// test
	if (value === undefined || value === null) {
		return false;
	}

	return new Date(value).toLocaleDateString() === filter.toLocaleDateString();
});

FilterService.register('DATERANGE', (value, filter): boolean => {
	if (filter === undefined || filter === null) {
		return true;
	}
	// test
	if (value === undefined || value === null) {
		return false;
	}

	const startDate = new Date(filter[0]).toLocaleDateString();
	const dateToFilter = new Date(value).toLocaleDateString();

	if (!filter[1]) {
		return dateToFilter === startDate;
	} else {
		return dateToFilter >= startDate && dateToFilter <= new Date(filter[1]).toLocaleDateString();
	}
});

FilterService.register('BOOLEANBADGECHECK', (value, filterArray): boolean => {
	if (filterArray === undefined || filterArray === null || filterArray.length === 0) {
		return true;
	}

	const selected = Array.from(filterArray, data => {
		return (data as any).value.toString();
	});

	return selected.includes(value.toString());
});

setupAxiosInterceptors(
	unauthenticatedError => {
		const error = unauthenticatedError;
		const url = error.response?.config?.url;
		const status = error.status || error.response.status;
		if (status === 401) {
			// Store logged out state.
			if (!url.equals('/')) {
				store.commit('logout');
			}

			if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
				// Ask for a new authentication
				router.push('/');
				return;
			}
		}
		console.error('unauthenticatedError:', error);
		return Promise.reject(error);
	},
	badRequestError => {
		console.error('badRequestError:', badRequestError);
		return Promise.reject(badRequestError);
	},
	internalServerError => {
		console.error('internalServerError:', internalServerError);
		return Promise.reject(internalServerError);
	},
);

watch(
	() => {
		return store.getters.currentLanguage;
	},
	currentLanguage => {
		translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage, emitter);
	},
);

app.mount('#app');
