import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class YouTrackService extends BaseService<any> {
	public constructor() {
		super('services/serviceyoutrack/api/youtrack');
	}

	public getRedirectUrl(): Promise<any> {
		return axios.get(this.endpointName + '/redirect-url');
	}

	public checkYouTrackUserSettingsExist(): Promise<any> {
		return axios.get(this.endpointName + '/check-settings');
	}
}
