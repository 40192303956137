import axios, { AxiosPromise } from 'axios';

export default class MetricsService {
	public getMetrics(): AxiosPromise<any> {
		return axios.get('management/jhimetrics');
	}

	public retrieveThreadDump(): AxiosPromise<any> {
		return axios.get('management/threaddump');
	}
}
