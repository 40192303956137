import { IAbsence } from '@/models/absence.model';
import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class AbsenceAdminService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/admin/absence');
	}

	public approveOrDisapprove(absence?: IAbsence): Promise<any> {
		return axios.put(`${this.endpointName}/approve?absenceId=${absence.id}`, absence);
	}
}
