import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class CalendarService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/calendar');
	}

	public getEventsByDate(startDate: string, endDate: string): Promise<any> {
		return axios.get(`${this.endpointName}?startDate=${startDate}&endDate=${endDate}`);
	}

	public getEventsByDateAndIds(startDate: string, endDate: string, employeeIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/get-by-employee-ids?startDate=${startDate}&endDate=${endDate}`, employeeIds);
	}
}
