import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class OrderLineService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/order-line');
	}

	public notBillable(orderId: string, timeLineIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/not-billable?orderId=${orderId}`, timeLineIds);
	}

	public assign(orderId: string, timeLineIds: Array<string>, setBillable: boolean): Promise<any> {
		return axios.post(`${this.endpointName}/assign?orderId=${orderId}&setBillable=${setBillable}`, timeLineIds);
	}

	public bill(orderLineBill: any): Promise<any> {
		return axios.post(`${this.endpointName}/bill`, orderLineBill);
	}

	public revert(timeLineIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/revert`, timeLineIds);
	}
}
