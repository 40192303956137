import axios from 'axios';

const TIMEOUT = 1000000;
const onRequestSuccess = config => {
	config.timeout = TIMEOUT;
	config.url = `${SERVER_API_URL}${config.url}`;
	return config;
};
const setupAxiosInterceptors = (onUnauthenticated, onBadRequest, onServerError) => {
	const onResponseError = err => {
		const status = err.status || err.response.status;

		switch (status) {
			case 400:
				return onBadRequest(err);
			case 401:
			case 403:
				return onUnauthenticated(err);
			case status > 500:
				return onServerError(err);
			default:
				console.error(`unhandled error status code [${status}]:`, err);
				return Promise.reject(err);
		}
	};

	if (axios.interceptors) {
		axios.interceptors.request.use(onRequestSuccess);
		axios.interceptors.response.use(res => res, onResponseError);
	}
};

export { onRequestSuccess, setupAxiosInterceptors };
