import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectLineAdminService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/admin/project-line');
	}

	public getOptions(projectId: string): Promise<any> {
		return axios.get(this.endpointName + '/combobox?projectId=' + projectId);
	}
}
