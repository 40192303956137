import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectCustomerService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/project-customer');
	}

	public getOptions(): Promise<any> {
		return axios.get(this.endpointName + '/combobox');
	}
}
