import axios from 'axios';
import { BaseService } from '@/core/base-service';
import { IEmployeeSuperior } from '@/models/employee-superior.model';

export default class EmployeeSuperiorService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/employee-superior');
	}

	public getByEmployeeId(employeeId: string): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}`);
	}

	public updateById(entity: IEmployeeSuperior): Promise<any> {
		return axios.put(`${this.endpointName}/${entity.id}`, entity);
	}

	public deleteById(entityId: string): Promise<any> {
		return axios.delete(`${this.endpointName}/${entityId}`);
	}

	public getById(entityId: string): Promise<any> {
		return axios.get(`${this.endpointName}/${entityId}`);
	}
}
