import axios from 'axios';
import { Store } from 'vuex';
import { getCookie } from '@rednex/gateway_core';

export default class AccountService {
	constructor(
		private store: Store<any>,
		private router: any,
	) {}

	public hasRole(role: string): boolean {
		const currentUser = this.store.getters.currentUser;
		if (currentUser) {
			role = role.startsWith('ROLE_') ? role : `ROLE_${role}`;
			return this.checkAuthorities(role);
		}

		return false;
	}

	public hasAuthority(authority: string): boolean {
		const currentUser = this.store.getters.currentUser;
		if (currentUser) {
			return this.checkAuthorities(authority);
		}

		return false;
	}

	public hasAnyRole(roles: Array<string>): boolean {
		const currentUser = this.store.getters.currentUser;
		if (currentUser) {
			return this.checkAuthorities(roles);
		}

		return false;
	}

	public hasAnyAuthority(authorities: []): boolean {
		const currentUser = this.store.getters.currentUser;
		if (currentUser) {
			return this.checkAuthorities(authorities);
		}

		return false;
	}

	public reloadAuthentication(authorities: any): Promise<boolean> {
		if (!getCookie('jhi-authenticationToken')) {
			return Promise.resolve(false);
		}

		if (!this.store.getters.isAuthenticated || !this.store.getters.currentUser?.authorities) {
			if (!this.store.getters.currentUser) {
				return this.retrieveAccount().then(res => {
					if (res) {
						return Promise.resolve(this.checkAuthorities(authorities));
					}
					return Promise.resolve(false);
				});
			}
			return Promise.resolve(false);
		}

		return Promise.resolve(this.checkAuthorities(authorities));
	}

	private retrieveAccount(): Promise<boolean> {
		return new Promise(resolve => {
			axios
				.get<any>('api/account')
				.then(response => {
					const account = response.data;
					if (account) {
						this.store.commit('setCurrentUser', account);
						this.store.commit('currentLanguage', account.langKey);

						if (sessionStorage.getItem('requested-url')) {
							this.router.replace(sessionStorage.getItem('requested-url'));
							sessionStorage.removeItem('requested-url');
						}
					} else {
						this.store.commit('logout');
						sessionStorage.removeItem('requested-url');
					}
					resolve(true);
				})
				.catch(() => {
					this.store.commit('logout');
					resolve(false);
				});
		});
	}

	private checkAuthorities(authoritiesToCheck: any): boolean {
		if (typeof authoritiesToCheck === 'string') {
			authoritiesToCheck = [authoritiesToCheck];
		}

		const currentUserAuthorities = this.store.getters.currentUser?.authorities;
		if (authoritiesToCheck && currentUserAuthorities) {
			for (const authority of authoritiesToCheck) {
				if (currentUserAuthorities.includes(authority)) {
					return true;
				}
			}
		}
		return false;
	}
}
