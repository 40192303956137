import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class DashboardService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/dashboard');
	}

	public getOpenRequests(): Promise<any> {
		return axios.get(`services/servicezef/api/absence/employee?completed=false&bookingType=USER&sort=startTime,DESC`);
	}

	public getSumShouldAttendances(date?: Date | string): Promise<any> {
		return axios.get(`${this.endpointName}/should-attendances/${date}`);
	}

	public getSumIsAttendances(date?: Date | string): Promise<any> {
		return axios.get(`${this.endpointName}/is-attendances/${date}`);
	}

	public getSumIsAttendancesWithHolidays(date?: Date | string): Promise<any> {
		return axios.get(`${this.endpointName}/is-attendances-with-holidays/${date}`);
	}

	public getSumAllProjectTimes(date?: Date | string, userId?: string): Promise<any> {
		return axios.get(`${this.endpointName}/all-project-times/${userId}/${date}`);
	}

	public getSumBillableTimes(date?: Date | string, userId?: string): Promise<any> {
		return axios.get(`${this.endpointName}/billable-project-times/${userId}/${date}`);
	}

	public getSumNotBillableTimes(date?: Date | string, userId?: string): Promise<any> {
		return axios.get(`${this.endpointName}/not-billable-project-times/${userId}/${date}`);
	}

	public getSumGoodwillTimes(date?: Date | string, userId?: string): Promise<any> {
		return axios.get(`${this.endpointName}/goodwill-project-times/${userId}/${date}`);
	}
}
