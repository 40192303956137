import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectLineService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/project-line');
	}

	public getOptions(projectId: string, date: string): Promise<any> {
		return axios.get(`${this.endpointName}/combobox?date=${date}&projectId=${projectId}`);
	}
}
