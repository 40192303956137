import { ApplicationOption } from '@/shared/model/application-option';
import { Authority } from '@/shared/model/authority';

const AbsenceTypeManagementComponent = () => import('@/project/absence-type-management/absence-type-management.vue');
const BillingComponent = () => import('@/project/billing/billing.vue');
const CalendarComponent = () => import('@/project/pze/calendar/calendar.vue');
const ClosedRequests = () => import('@/project/requests/closed-requests.vue');
const CompanyComponent = () => import('@/project/company/company.vue');
const DashboardComponent = () => import('@/project/pze/dashboard/dashboard.vue');
const DocumentTypeManagementComponent = () => import('@/project/document-type-management/document-type-management.vue');
const EmployeeManagementComponent = () => import('@/project/employee-management/employee-management.vue');
const FavoriteComponent = () => import('@/project/pze/favorite/favorite.vue');
const JournalComponent = () => import('@/project/journal/journal.vue');
const OrderLinesComponent = () => import('@/project/pze/orders/order-lines.vue');
const OrdersComponent = () => import('@/project/pze/orders/orders.vue');
const OpenRequests = () => import('@/project/requests/open-requests.vue');
const ProjectAccessComponent = () => import('@/project/pze/project-access-management/project-access-management.vue');
const ProgramSettingsComponent = () => import('@/project/program-setting/program-setting.vue');
const ProjectCustomerComponent = () => import('@/project/pze/project-customer-management/project-customer-management.vue');
const ProjectCategoryComponent = () => import('@/project/pze/project-category-management/project-category-management.vue');
const ProjectDetailsComponent = () => import('@/project/pze/project-management/project-details.vue');
const ProjectManagementComponent = () => import('@/project/pze/project-management/project-management.vue');
const ProjectTemplateManagementComponent = () => import('@/project/pze/project-templates/project-template-management.vue');
const ProjectTemplateDetailsComponent = () => import('@/project/pze/project-templates/project-template-details.vue');
const TeamCalendar = () => import('@/project/team-calendar/team-calendar.vue');
const TeamCalendarPZE = () => import('@/project/team-calendar-pze/team-calendar-pze.vue');
const TimeLineComponent = () => import('@/project/pze/time-line/time-line.vue');
const TimeLineOverviewComponent = () => import('@/project/pze/time-line-overview/time-line-overview.vue');
const ServiceAgreementManagementComponent = () => import('@/project/service-agreement-management/service-agreement-management.vue');

export default [
	{
		path: '/absence-type',
		name: 'AbsenceType',
		applicationOption: ApplicationOption.ZEF,
		component: AbsenceTypeManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/billing',
		name: 'Billing',
		applicationOption: ApplicationOption.ZEF,
		component: BillingComponent,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.ADMIN_EMPLOYEE],
			applicationOption: ApplicationOption.ZEF,
		},
	},
	{
		path: '/requests-closed',
		name: 'ClosedRequestsUser',
		applicationOption: ApplicationOption.ZEF,
		component: ClosedRequests,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.ZEF,
		},
	},
	{
		path: '/admin/time-line-overview',
		name: 'TimeLineOverviewComponent',
		applicationOption: ApplicationOption.PZE,
		component: TimeLineOverviewComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/supervisor-requests-closed',
		name: 'SupervisorClosedRequestsUser',
		applicationOption: ApplicationOption.ZEF,
		component: ClosedRequests,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN_EMPLOYEE], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/document-type',
		name: 'DocumentType',
		applicationOption: ApplicationOption.ZEF,
		component: DocumentTypeManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/employee',
		name: 'Employee',
		applicationOption: ApplicationOption.ZEF,
		component: EmployeeManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/journal',
		name: 'Journal',
		applicationOption: ApplicationOption.ZEF,
		component: JournalComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.USER], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/requests-open',
		name: 'OpenRequestsUser',
		applicationOption: ApplicationOption.ZEF,
		component: OpenRequests,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.ZEF,
		},
	},
	{
		path: '/supervisor-requests-open',
		name: 'SupervisorOpenRequestsUser',
		applicationOption: ApplicationOption.ZEF,
		component: OpenRequests,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN_EMPLOYEE], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/service-agreement',
		name: 'ServiceAgreement',
		applicationOption: ApplicationOption.ZEF,
		component: ServiceAgreementManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/admin/project-access/:id',
		name: 'ProjectAccess',
		component: ProjectAccessComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project-line-access/:id',
		name: 'ProjectLineAccess',
		component: ProjectAccessComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project-category',
		name: 'ProjectCategory',
		applicationOption: ApplicationOption.PZE,
		component: ProjectCategoryComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project-customer',
		name: 'ProjectCustomers',
		applicationOption: ApplicationOption.PZE,
		component: ProjectCustomerComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project-templates',
		name: 'ProjectTemplates',
		applicationOption: ApplicationOption.PZE,
		component: ProjectTemplateManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project/:id',
		name: 'ProjectDetails',
		component: ProjectDetailsComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/project-templates/:id',
		name: 'ProjectTemplateDetails',
		component: ProjectTemplateDetailsComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/projects',
		name: 'Projects',
		applicationOption: ApplicationOption.PZE,
		component: ProjectManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/team-calendar',
		name: 'TeamCalendar',
		applicationOption: ApplicationOption.ZEF,
		component: TeamCalendar,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.ZEF,
		},
	},
	{
		path: '/admin/team-calendar',
		name: 'TeamCalendarPZE',
		applicationOption: ApplicationOption.PZE,
		component: TeamCalendarPZE,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/view-calendar',
		name: 'View Calendar',
		applicationOption: ApplicationOption.ZEF,
		component: CalendarComponent,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.PZE,
		},
	},
	{
		path: '/time-line',
		name: 'Time line',
		component: TimeLineComponent,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.PZE,
		},
	},
	{
		path: '/favorites',
		name: 'Favorites',
		applicationOption: ApplicationOption.PZE,
		component: FavoriteComponent,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.PZE,
		},
	},
	{
		path: '/settings/program/:code?',
		name: 'Program settings',
		component: ProgramSettingsComponent,
		meta: {
			authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
			applicationOption: ApplicationOption.SETTINGS,
		},
	},
	{
		path: '/settings/company',
		name: 'Company settings',
		component: CompanyComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/orders',
		name: 'Orders',
		component: OrdersComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/admin/order-lines',
		name: 'Order lines',
		component: OrderLinesComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.PZE },
	},
	{
		path: '/dashboard-zef',
		name: 'DashboardZef',
		component: DashboardComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER], applicationOption: ApplicationOption.ZEF },
	},
	{
		path: '/dashboard-pze',
		name: 'DashboardPze',
		component: DashboardComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER], applicationOption: ApplicationOption.PZE },
	},
];
