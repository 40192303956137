import axios from 'axios';
import generateQueryOptions from '@/shared/sort/sorts';

export class BaseService<Type> {
	protected endpointName: string;

	public constructor(endpointName: string) {
		this.endpointName = endpointName;
	}

	public get(id: any): Promise<any> {
		return axios.get(this.endpointName + `/${id}`);
	}

	public create(object: Type): Promise<any> {
		return axios.post(this.endpointName, object);
	}

	public update(id: any, object: Type): Promise<any> {
		return axios.put(this.endpointName + `/${id}`, object);
	}

	public delete(id: any): Promise<any> {
		return axios.delete(this.endpointName + `/${id}`);
	}

	public retrieve(req?: any): Promise<any> {
		return axios.get(this.endpointName + generateQueryOptions(req));
	}
}
