export const languageOptions = [
	{ label: '🇩🇪', value: 'de' },
	{ label: '🇺🇸', value: 'en' },
];

export const saldoTypeOptions = [
	{ label: 'journal.holidayBalance', value: 'HOLIDAY' },
	{ label: 'journal.timeBalance', value: 'TIME' },
	{ label: 'journal.noBalance', value: 'NONE' },
];

export const timeUnitTypeOptions = [
	{ label: 'absenceType.hours', value: 'HOURS' },
	{ label: 'absenceType.days', value: 'DAYS' },
];
