import axios, { AxiosPromise } from 'axios';

export default class LogsService {
	public changeLevel(name: string, configuredLevel: string): AxiosPromise<any> {
		return axios.post(`management/loggers/${name}`, { configuredLevel });
	}

	public findAll(): AxiosPromise<any> {
		return axios.get('management/loggers');
	}
}
