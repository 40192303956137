import axios from 'axios';
import { BaseService } from '@/core/base-service';
import { WorkingHoursType } from '@/shared/model/enumerations/working-hours-type.model';
import { IWorkingHours } from '@/models/working-hours.model';

export default class WorkingHoursService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/working-hours');
	}

	public cancel(object: object): Promise<any> {
		return axios.post(`${this.endpointName}/cancel`, object);
	}

	public retrieveByEmployeeId(employeeId: string, type: WorkingHoursType): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}&type=${type}&sort=startTime,DESC`);
	}

	public deleteById(entityId: string): Promise<any> {
		return axios.delete(`${this.endpointName}/${entityId}`);
	}

	public retrieveById(entityId: string): Promise<any> {
		return axios.get(`${this.endpointName}/${entityId}`);
	}

	public updateById(entity: IWorkingHours): Promise<any> {
		return axios.put(`${this.endpointName}/${entity.id}`, entity);
	}
}
