import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectLineTemplateService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/project-line-template');
	}

	public addLinesToProject(project: any, templateId: string): Promise<any> {
		return axios.get(`${this.endpointName}/add-template-lines-to-project?projectId=${project.id}&templateId=${templateId}`);
	}
}
