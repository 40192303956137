import { computed } from '@vue/reactivity';
import { maxLength } from '@vuelidate/validators';
import { minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
import { required } from '@vuelidate/validators';
import { sameAs } from '@vuelidate/validators';

export const defaultObject = {
	newPassword: '',
	confirmPassword: '',
};

export const validationState = reactive({
	...defaultObject,
});

export const validationRules = computed(() => {
	return {
		newPassword: {
			required,
			minLength: minLength(6),
			maxLength: maxLength(254),
		},
		confirmPassword: {
			required,
			minLength: minLength(6),
			maxLength: maxLength(254),
		},
	};
});
