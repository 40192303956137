import { Authority } from '@/shared/model/authority';
import { ApplicationOption } from '@/shared/model/application-option';

const TenantManagementComponent = () => import('@/admin/tenant-management/tenant-management.vue');
const JhiUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const JhiUserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const JhiUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const JhiGatewayComponent = () => import('@/admin/gateway/gateway.vue');

export default [
	{
		path: '/admin/tenant-management',
		name: 'Tenant',
		component: TenantManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/user-management',
		name: 'JhiUser',
		component: JhiUserManagementComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/user-management/new',
		name: 'JhiUserCreate',
		component: JhiUserManagementEditComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/user-management/:userId/edit',
		name: 'JhiUserEdit',
		component: JhiUserManagementEditComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/user-management/:userId/view',
		name: 'JhiUserView',
		component: JhiUserManagementViewComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/apidocs',
		name: 'JhiDocsComponent',
		applicationOption: ApplicationOption.SETTINGS,
		component: JhiDocsComponent,
		meta: { authorities: [Authority.SUPERADMIN, Authority.ADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/health',
		name: 'JhiHealthComponent',
		component: JhiHealthComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/logs',
		name: 'JhiLogsComponent',
		component: JhiLogsComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/metrics',
		name: 'JhiMetricsComponent',
		component: JhiMetricsComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/configuration',
		name: 'JhiConfigurationComponent',
		component: JhiConfigurationComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
	{
		path: '/admin/gateway',
		name: 'JhiGatewayComponent',
		component: JhiGatewayComponent,
		meta: { authorities: [Authority.SUPERADMIN], applicationOption: ApplicationOption.SETTINGS },
	},
];
