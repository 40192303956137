import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class TimeLineService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/time-line');
	}

	public getWorkTypes(workItemNumber: string): Promise<any> {
		return axios.put(`${this.endpointName}/work-type?workItemNumber=${workItemNumber}`);
	}

	public deleteSelected(selectedIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/delete-selected`, selectedIds);
	}

	public release(selectedIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/release-selected`, selectedIds);
	}

	public unRelease(selectedIds: Array<string>): Promise<any> {
		return axios.post(`${this.endpointName}/unrelease-selected`, selectedIds);
	}

	public youTrackSync(timeLineId: string): Promise<any> {
		return axios.put(`${this.endpointName}/youtrack-sync?id=${timeLineId}`);
	}

	public checkWorkItem(workItemNumber: string): Promise<any> {
		return axios.put(`${this.endpointName}/check-work-item?workItemNumber=${workItemNumber}`);
	}
}
