import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class OrderService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/order');
	}

	public getFirstWithBillableLines(id: string): Promise<any> {
		return axios.get(this.endpointName + `/get-first-with-billable-lines/${id}`);
	}
}
