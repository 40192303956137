import axios from 'axios';
import { BaseService } from '@/core/base-service';
import { IEmployeeServiceAgreement } from '@/models/employee-service-agreement.model';

export default class AbsenceService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/absence');
	}

	public getAbsenceOverview(month?: any): Promise<any> {
		return axios.get(`${this.endpointName}/team?month=${month}`);
	}

	public updateById(entity: IEmployeeServiceAgreement): Promise<any> {
		return axios.put(`${this.endpointName}/${entity.id}`, entity);
	}

	public getEntitlementBookings(employeeId?: string): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}&bookingType=ADMIN&sort=startTime,DESC`);
	}
}
