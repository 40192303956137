import { Module } from 'vuex';

export interface ProjectStateStorable {
	currentApplication: string;
	latestTimeLineDate: Date;
}

function initCurrentApplication(): string {
	const currentApplication = sessionStorage.getItem('current-application');
	return currentApplication ?? 'zef'; //TODO maybe change this once pages exist
}

function initLatestTimeLineDate(): Date {
	const localStorageDate = localStorage.getItem('latest-timeline-date');
	return localStorageDate ? new Date(localStorageDate) : new Date(Date.now());
}

export const defaultProjectState: ProjectStateStorable = {
	currentApplication: initCurrentApplication(),
	latestTimeLineDate: initLatestTimeLineDate(),
};

export const projectStore: Module<ProjectStateStorable, any> = {
	state: { ...defaultProjectState },
	getters: {
		currentApplication: state => state.currentApplication,
		latestTimeLineDate: state => state.latestTimeLineDate,
	},
	mutations: {
		setCurrentApplication(state, currentApplication) {
			state.currentApplication = currentApplication;
			sessionStorage.setItem('current-application', currentApplication);
		},
		setLatestTimeLineDate(state, latestTimeLineDate) {
			state.latestTimeLineDate = latestTimeLineDate;
			localStorage.setItem('latest-timeline-date', latestTimeLineDate);
		},
	},
};
