import axios from 'axios';

const baseApiUrl = 'api/master/schema';
export default class SchemaService {
	public getServiceApplications(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`services/jhipster-registry/api/eureka/applications`)
				.then(res => {
					const applications = [];
					const applicationsFromRegistry = res?.data?.applications;
					for (let i = 0; i < applicationsFromRegistry.length; i++) {
						if (
							applicationsFromRegistry[i].instances[0].status === 'UP' &&
							!applicationsFromRegistry[i].name.toLowerCase().includes('jhipster-registry') &&
							!applicationsFromRegistry[i].name.toLowerCase().includes('gateway')
						) {
							applications.push(applicationsFromRegistry[i].name.toLowerCase());
						}
					}
					resolve(applications);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	public runLiquibase(servicename: string, schema: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`services/${servicename}/${baseApiUrl}/runLiquibase/${schema}`)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	public runLiquibaseAll(servicename: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`services/${servicename}/${baseApiUrl}/runLiquibaseAll`)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}
