import { BaseService } from '@/core/base-service';
import axios from 'axios';
import { getDateTimeFromDateTime } from '@rednex/gateway_core';

export default class PzeTimeRecordingService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/time-recording');
	}

	public checkTimeRecording(): Promise<any> {
		return axios.get(this.endpointName + `/get-by-current-user`);
	}

	public setTimeRecordingStartEnd(description: string, isTimeRecording: boolean): Promise<any> {
		return axios.post(this.endpointName + '/set-time-recording-start-end', {
			description: description,
			enabled: isTimeRecording,
			startTime: getDateTimeFromDateTime(new Date()),
		});
	}

	public checkRunningTimeRecording(): Promise<any> {
		return axios.post(this.endpointName + '/check-running-time-recording');
	}

	public resumeTimeRecording(data: object): Promise<any> {
		return axios.post(this.endpointName + '/resume-time-recording', data);
	}

	public updateTimeRecordingDescription(description: string): Promise<any> {
		return axios.post(this.endpointName + '/update-description', { description });
	}
}
