import axios from 'axios';
import { BaseService } from '@/core/base-service';
import { IEmployeeServiceAgreement } from '@/models/employee-service-agreement.model';

export default class EmployeeServiceAgreementService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/employee-service-agreement');
	}

	public retrieveByEmployeeId(employeeId: string): Promise<any> {
		return axios.get(`${this.endpointName}/employee?employeeId=${employeeId}&sort=startDate,ASC`);
	}

	public deleteById(entityId: string): Promise<any> {
		return axios.delete(`${this.endpointName}/${entityId}`);
	}

	public retrieveById(entityId: string): Promise<any> {
		return axios.get(`${this.endpointName}/${entityId}`);
	}

	public updateById(entity: IEmployeeServiceAgreement): Promise<any> {
		return axios.put(`${this.endpointName}/${entity.id}`, entity);
	}
}
