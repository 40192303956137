import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class AbsenceTypeService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/absence-type');
	}

	public getOptions(): Promise<any> {
		return axios.get(this.endpointName + '/combobox');
	}
}
