import axios from 'axios';
import { AxiosInstance } from 'axios';

export default class ActivateService {
	private axios: AxiosInstance;

	constructor() {
		this.axios = axios;
	}

	public changePassword(userId: string, newPassword: string): Promise<any> {
		return this.axios.post('api/account/change-password', { userId, newPassword });
	}
}
