export default function generateQueryOptions(req) {
	if (req) {
		let queryOptions = '';
		if (req.sort) {
			for (const idx of Object.keys(req.sort)) {
				queryOptions += queryOptions.length > 0 ? '&' : '?';
				queryOptions += 'sort=' + req.sort[idx];
			}
		}

		if (req.page && req.size) {
			queryOptions += queryOptions.length > 0 ? '&' : '?';
			queryOptions += 'page=' + req.page + '&size=' + req.size;
		}

		return (req.filterParam ? req.filterParam : '') + queryOptions;
	}
	return '';
}
