import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class CompanyService extends BaseService<any> {
	public constructor() {
		super('services/servicezef/api/admin/company'); // @TODO -> ?
	}

	public getCompanyByTenant(): Promise<any> {
		return axios.get(`${this.endpointName}/get-company-by-tenant`);
	}

	public updateById(object: any): Promise<any> {
		return axios.put(`${this.endpointName}/${object.id}`, object);
	}
}
