import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class ProjectAdminService extends BaseService<any> {
	public constructor() {
		super('services/servicepze/api/admin/project');
	}

	public getOptions(customerId: string): Promise<any> {
		return axios.get(this.endpointName + '/combobox' + (customerId ? '?customerId=' + customerId : ''));
	}
}
